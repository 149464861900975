@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: "Artsakh";
  src: url("../public/fonts/Artsakh-Font.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "ArmHelv";
  src: url("../public/fonts/Arm_Helv.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Norkirk";
  src: url("../public/fonts/NorKirk.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "kotayk";
  src: url("../public/fonts/Kotayk-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "namu";
  src: url("../public/fonts/namu.ttf") format("truetype");
  font-display: swap;
}

:root {
  /* C O L O R S */
  --bcg-primary: #ffffff;
  --bcg-primary-dark: #2a3342;
  --bcg-secondary: #b4d0d9;
  --bcg-secondary-dark: #538494;
  --text-primary-orange: #f74f21;
  --text-primary: #474747;
  --text-main: #5a4b42;
  --text-selected: #edcd81;
  --bcg-green: #22c55e;
  --bcg-green-hover: #1dbd4c;
  --prm-blue: #3172e6;
  --prm-blue-hover: #2f66cf;

  /* F O N T S */
  --font-primary: "Poppins", sans-serif;
  --font-header: "Norkirk", sans-serif;
  --font-arm-small: "ArmHelv", sans-serif;

  --swiper-pagination-color: var(--text-primary-orange);
}

body {
  font-family: var(--font-primary);
  font-size: 1.2rem;
  background-color: white;
  color: #2a3342;
  margin: 0;
  padding: 0;
}

.App {
  margin: 0;
  padding: 0;
}

.dropdown-menu {
  right: 0 !important;
  padding: 0;
  font-size: 11px;
}

/* C A R O U S E L  */

.carousel-inner {
  overflow: visible;
}

/* S W I P E R */

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

/* SWIPER JS */

.swiper-wrapper {
  margin-bottom: 30px;
  align-items: baseline;
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}

.swiper-pagination {
  display: flex;
  justify-content: center;
  gap: 7px;
}

.swiper-pagination-bullet {
  height: 13px !important;
  width: 13px !important;
}

/* TRUNCATE FOR SAFARI */
.truncate-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  max-height: 3.2em;
  line-height: 1.6;
}
