.projects-main {
  padding: 0;
}

.projects-container {
  width: 80%;
  height: 100%;
  margin: 0 auto;
}

.projects-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px auto 30px auto;
}

.projects-header h3 {
  font-size: 48px;
  font-weight: 700;
  color: #1c232d;
}

.projects-header button {
  font-size: 14px;
  font-weight: 500;
  width: 147px;
  min-height: 36px;
  background-color: var(--bcg-green);
  box-shadow: 0px 1px 2px rgba(105, 81, 255, 0.05);
  border-radius: 6px;
  color: white;
  border: none !important;
}

.projects-header button:hover {
  background-color: var(--bcg-green-hover);
}

.dropdown-menu {
  right: 0 !important;
  padding: 0;
}

.project-indicators {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.project-indicators span {
  margin-left: 3px;
  color: var(--bcg-secondary-dark);
  cursor: pointer;
}

/* M O D A L */

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.modal-footer {
  margin-top: 0 !important;
  color: white !important;
}

.modal-footer button {
  color: white !important;
}

.modal-footer button:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

form {
  width: 100%;
}

.selected-language {
  margin-top: 20px;
  font-size: 20px;
}

.selected-language span {
  cursor: pointer;
  margin: 0 4px;
}

.selected-language .selected {
  color: #edcd81;
  font-weight: 700;
}

/* M E D I A  Q U E R I E S */

@media screen and (max-width: 768px) {
  .projects-container {
    margin: 0 auto;
    width: 90%;
  }

  .projects-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
  }

  .projects-header h3 {
    font-size: 30px;
    line-height: 35px;
  }

  .projects-header button {
    font-size: 15px;
    font-weight: 400;
    width: 220px;
    height: 100%;
    background-color: transparent;
    box-shadow: 0px 1px 2px rgba(105, 81, 255, 0.05);
    color: var(--bcg-green);
    border: none !important;
    text-align: start;
    padding: 0;
    text-transform: uppercase;
  }

  .project-create-date {
    right: 5px;
  }

  .project-indicators {
    display: none;
  }
}
