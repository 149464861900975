.donations > button {
  background-color: #ffffff;
  padding: 10px 30px;
  border-radius: 4px;
  color: var(--bcg-primary-dark);
}

.closeButton {
  position: absolute;
  cursor: pointer;
  font-size: 0.8rem;
  z-index: 100;
}

#dropdown-currency {
  color: rgba(0, 0, 0, 0.489);
  padding: 10px;
  border: none;
  background-color: white;
  height: 100%;
}

#dropdown-currency:hover {
  border: none;
  background-color: white;
}

.custom-values input:focus-visible,
.custom-values input:focus {
  border: none !important;
  outline: none;
}

.custom-values .dropdown-menu {
  font-size: 0.8rem !important;
  transform: translate3d(9px, 52.3636px, 0px) !important;
}

.custom-values .no-spinner::-webkit-inner-spin-button,
.custom-values .no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-values .no-spinner {
  -moz-appearance: textfield;
}

input[type="checkbox"] {
  appearance: none; /* Standard */
  -webkit-appearance: none; /* Safari and older browsers */
  background-color: #fff;
  border: 1px solid #ccc;
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 6px; /* Optional for rounded corners */
  transition:
    background-color 0.2s ease,
    border-color 0.2s ease;
}

/* Style for checked state */
input[type="checkbox"]:checked {
  background-color: #22c55e; /* Fill color */
  border-color: #22c55e; /* Optional border change */
}

/* M O D A L  */
.modal-body {
  padding: 0 !important;
  border: none;
  outline: none;
}

.modal-dialog {
  max-width: 400px !important;
}

/*G L O B A L   H O V E R  O P T I O N */

@media (hover: hover) {
  .donations.covered {
    transition: right 0.4s ease;
    right: -130px;
    position: fixed;
    z-index: 50;
    top: 50%;
  }

  .donations.covered:hover {
    right: -20px;
  }

  .donations.covered > button {
    border-radius: 50px 0 0 50px;
    box-shadow: 0 1px 4px var(--text-main);
    border-right: none;
    padding-left: 15px;
  }
}

/* A N I M A T I O N */

.clicked {
  animation: flyAndDisappear 2s linear forwards;
}

@keyframes flyAndDisappear {
  0% {
    transform: translateY(0) scale(1) rotate(0deg);
    opacity: 1;
  }
  50% {
    transform: translateY(-100px) scale(1.2) rotate(22deg);
    opacity: 1;
  }
  100% {
    transform: translateY(-200px) scale(1.3) rotate(-10deg);
    opacity: 0;
  }
}

.heartbeat {
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both;
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
