.privacy-wrapper {
    width: 80%;
    height: 100%;
    margin: 0 auto;
}

.privacy {
    color: black;
    margin-top: 80px;
    padding: 20px;
    min-height: calc(100vh - 80px);
}

.privacy h2 {
    margin-bottom: 20px;
    font-size: calc(20px + 0.8vw);
}

.privacy p {
    font-size: clamp(14px, calc(14px + 0.2vw), 16px);
    margin-bottom: 25px;
}

.privacy h4 {
    font-size: clamp(16px, calc(16px + 0.5vw), 22px);
    margin-bottom: 20px;
}

.privacy h5 {
    font-size: 22px;
    font-weight: medium;
}

.privacy ul {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.privacy li {
    font-size: 16px;
}

.privacy h5 {
    font-size: 16px;
}

.privacy div {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

@media screen and (max-width: 768px) {
    .privacy-wrapper {
        width: 90%;
        height: 100%;
        margin: 0 auto;
    }

    .privacy h2 {
        font-size: calc(20px + 0.5vw);
    }
}
