.home-news {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--bcg-primary-dark);
    padding: 20px 0;
    width: 100%;
    min-height: 792px;
    height: 100%;
    position: relative;
    gap: 50px;
}

.home-news-header {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.home-news-header h3 {
    font-size: 48px;
    font-weight: 700;
}

@media screen and (max-width: 768px) {
    .home-news-header {
        width: 95%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }

    .home-news-header h3 {
        font-size: 32px;
        font-weight: 700;
    }

    .home-news-header button {
        font-size: 16px;
        font-weight: 500;
        text-align: start;
        width: 100%;
        height: 20px;
        padding: 0;
        background-color: transparent;
        color: var(--bcg-green);
    }

    .home-news-header button:hover {
        background-color: transparent;
    }
}
