.partners {
  color: var(--bcg-primary-dark);
  margin-top: 80px;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.partners-main-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  min-height: 700px;
  height: 100%;
  gap: 20px;
  padding: 60px 0 30px 0;
}

.partners-main-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 9px;
}

.partners-header {
  position: relative;
}

.partners-header h2 {
  font-weight: 700;
  font-size: 38px;
  line-height: 72px;
}

.partners-header p {
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  font-style: italic;
}

.partners-body {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  height: 100%;
  padding: 0 30px 30px 30px;
  gap: 30px;
  flex-wrap: wrap;
}

.single-partner {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  z-index: 2;
  display: flex;
  user-select: none;
  cursor: pointer;
  box-sizing: border-box;
}

.single-partner-image {
  height: 100%;
  width: 100%;
}

.single-partner img {
  overflow: hidden;
  width: 100%;
  object-fit: contain;
  height: 100%;
  filter: saturate(0) brightness(1) contrast(1);
}

.single-partner img:hover {
  filter: none;
}

.single-partner-text {
  box-shadow: 0px 1px 2px rgba(18, 17, 26, 0.156);
  padding: 10px;
}

.single-partner span {
  font-size: 0.9rem;
  color: var(--text-main);
  text-align: center;
  height: 100%;
  font-weight: bold;
}

.mobile-button-header {
  display: none;
}

@media screen and (max-width: 768px) {
  .partners-main-wrapper {
    padding: 30px 0 15px 0;
  }

  .partners-main-wrapper img {
    object-fit: contain;
  }

  .partners-header h2 {
    font-weight: 700;
    font-size: 26px;
    line-height: 72px;
  }

  .partners-header p {
    font-size: 13px;
    line-height: 23px;
  }

  .single-partner span {
    font-size: 0.7rem;
  }

  .partners-body {
    padding: 5px;
    gap: 8px;
    justify-content: space-around;
  }
}
