/* T E A M S */

.teams {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 100%;
  height: 100%;
}

.our-team-header h2 {
  font-weight: 700;
  font-size: 48px;
  color: var(--text-primary-orange);
  line-height: 60px;
}

.our-team-header h4 {
  font-weight: 500;
  font-size: 16px;
  color: var(--text-primary);
  line-height: 18px;
}

.team-container {
  display: flex;
  flex-grow: inherit;
  justify-content: flex-start;
  align-items: flex-start;
}

.team-member-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.member-header {
  max-width: 259px;
  aspect-ratio: 1/1;
  padding: 5px;
}

.member-header img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
  object-position: top;
}

.member-body {
  padding: 5px;
}

.member-body h4 {
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  font-size: 22px;
  line-height: 44px;
  color: var(--text-primary-orange);
}

.member-body p {
  font-weight: 300;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  color: var(--text-primary);
}

/* M E D I A  Q U E R R I E S  */

@media screen and (max-width: 768px) {
  .team-member {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .member-header {
    width: 359px;
    padding: 5px;
    margin: 0 auto;
  }

  .our-team-header h2 {
    font-size: 28px;
    line-height: 60px;
  }

  .our-team-wrapper {
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }
}
