.dropdown-menu {
    right: 30px !important;
    top: 2px;
    user-select: none;
}

.dropDown-item {
    background-color: none !important;
}

.dropdown-item:hover {
    background: #b4d0d931 !important;
}

.dropdown-item:focus {
    background: none !important;
    color: black;
}

/* L O A D E R */

.error {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    width: 100%;
}

/* E R R O R  */

.error h1 {
    display: flex;
    align-items: center;
    gap: 10px;
}

.error svg {
    color: #ca0b00;
}

@media screen and (max-width: 768px) {
    .dropdown-item:hover {
        background: none;
    }

    .dropdown-item:focus {
        background: #b4d0d931 !important;
        color: black;
    }
}

/* P A G I N A T I O N  */

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    margin-top: 20px;
}

.page-link {
    border: none !important;
    color: var(--text-main);
    z-index: 1;
    padding: 0.275rem 0.55rem;
}

.page-link:focus {
    box-shadow: none !important;
    color: var(--bcg-secondary-dark);
    background-color: transparent !important;
}

.page-item.active .page-link {
    background-color: #ed9281 !important;
}

.page-link:hover {
    color: var(--bcg-secondary-dark);
    border-radius: 3px;
}

.page-link span:first-child {
    z-index: 2;
    width: 100%;
    height: 100%;
    padding: 0.375rem 0.25rem;
}

@media screen and (max-width: 991px) {
    .article {
        padding: 2px;
    }
}
