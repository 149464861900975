.news {
  padding: 0;
}

.article-container {
  width: 80%;
  height: 100%;
  margin: 0 auto;
}

.news-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px auto 30px auto;
}

.news-header h3 {
  font-size: 48px;
  font-weight: 700;
  color: #1c232d;
}

.news-header button {
  font-size: 14px;
  font-weight: 500;
  width: 147px;
  height: 36px;
  background-color: var(--bcg-green);
  box-shadow: 0px 1px 2px rgba(105, 81, 255, 0.05);
  border-radius: 6px;
  color: white;
  border: none !important;
}

.news-header button:hover {
  background-color: var(--bcg-green-hover);
}

@media screen and (max-width: 768px) {
  .article-container {
    width: 90%;
    height: 100%;
    margin: 0 auto;
  }

  .news-body {
    gap: 20px;
  }

  .news-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .news-header h3 {
    font-size: 32px;
    font-weight: 700;
  }

  .news-header button {
    font-size: 16px;
    font-weight: 500;
    text-align: start;
    width: 100%;
    height: 20px;
    padding: 0;
    background-color: transparent;
    color: var(--bcg-green);
  }

  .news-header button:hover {
    background-color: transparent;
  }
}
